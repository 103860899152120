import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux'
import { Col, Container, Form, Modal, Nav, Row, Tab } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import LoadingOverlay from 'react-loading-overlay';

import store from '../store'
import * as actions from "../actions/"
import * as recentReducer from '../reducers/recentActivity'
import { TeacherEmail } from "../api/teacher";

import Header from "./components/Header";
import AssignedBooks from "./AssignedBooks";
import RecentActivity from "./Recent_Activity";
import Student from "./Student";
import StudentModal from "./StudentModal";
import { LanguageAPI } from "../api/language";


export default () => {
    const isLoading = useSelector(state => store.getState().recent.isLoading);
    const assignedRef = useRef();

    const handleChange = () => {
        if(klass){
            store.dispatch(actions.setReqData({
                "teacher_id"    : TeacherEmail(),
                "class_name"    : klass,
                "target_lang"   : lang,
                "offset"        : 0
            }))
            store.dispatch(recentReducer.initData)
            assignedRef.current.closeAssignedBookDetail()
        }
        // first_load = false
    }

    // To swap image of tabs
    const inactiveImg = {
        recent_activity: process.env.PUBLIC_URL + '/images/recent_activity_icon.svg',
        assigned_books: process.env.PUBLIC_URL + '/images/assigned_books_icon.svg',
        student: process.env.PUBLIC_URL + '/images/student_icon.svg'
    }
    const activeImg = {
        recent_activity: process.env.PUBLIC_URL + '/images/recent_activity_active_icon.svg',
        assigned_books: process.env.PUBLIC_URL + '/images/assigned_books_active_icon.svg',
        student: process.env.PUBLIC_URL + '/images/student_active_icon.svg'
    }

    const klassList = store.getState().dashboardConfig

    const [klass, setKlass] = useState('')
    const [lang, setLang] = useState("EN")

    const [imgRecent, setImgRecent] = useState(activeImg['recent_activity'])
    const [imgAssigned, setImgAssigned] = useState(inactiveImg['assigned_books'])
    const [imgStudent, setImgStudent] = useState(inactiveImg['student'])

    useEffect(() => {
        if (store.getState().dashboardConfig.length > 0) setKlass(store.getState().dashboardConfig[0].class_id)
    }, [store.getState().dashboardConfig])
    const handleKlassChange = (event) => {
        setKlass(event.target.value)
    }

    const handleLangChange = (event) => {
        setLang(event.target.value)
    }

    useEffect(() => {
        handleChange()
    }, [klass, lang])

    const handleTabClick = (e) => {
        if (e==="recent_activity") {
            setImgRecent(activeImg['recent_activity'])
            setImgAssigned(inactiveImg['assigned_books'])
            setImgStudent(inactiveImg['student'])
        }
        else if (e==="assigned_books") {
            setImgRecent(inactiveImg['recent_activity'])
            setImgAssigned(activeImg['assigned_books'])
            setImgStudent(inactiveImg['student'])
        }
        else if (e==="student") {
            setImgRecent(inactiveImg['recent_activity'])
            setImgAssigned(inactiveImg['assigned_books'])
            setImgStudent(activeImg['student'])
        }
    }
    const ClassDropdown = () => {
        return (
            <span className='mb-1' style={{maxWidth: "200px", display: "inline-block"}}>
                <select
                    id="class"
                    className="form-select font-size-sm bg-transparent py-0 ps-2 pe-4 fw-bold color-grey700"
                    style={{maxWidth: "200px", backgroundPosition: 'right .4rem center'}}
                    value={klass}
                    onChange={handleKlassChange}
                >
                    {
                        klassList.map((klass, index) => {
                            return <option value={klass.class_id} key={index}>{klass.title}</option>
                        })
                    }
                </select>
            </span>)
    }

    const [standardModalShow, setStandardModalShow] = useState(false)
    const [standard, setStandard] = useState(localStorage.getItem('standard'))
    const standard_dict = {
        'elpa21': 'https://sw-materials-documents.s3.amazonaws.com/documents/standards-and-assessments/ELPA21.pdf',
        'actfl' : 'https://sw-materials-documents.s3.amazonaws.com/documents/standards-and-assessments/ACTFL.pdf',
        'wida'  : 'https://sw-materials-documents.s3.amazonaws.com/documents/standards-and-assessments/WIDA.pdf',
        'ccss'  : 'https://sw-materials-documents.s3.amazonaws.com/documents/standards-and-assessments/CCSS.pdf',
        'ca'    : 'https://sw-materials-documents.s3.amazonaws.com/documents/standards-and-assessments/CA-ELD.pdf',
        'ny'    : 'https://sw-materials-documents.s3.amazonaws.com/documents/standards-and-assessments/NY-ELA.pdf',
        'tx'    : 'https://sw-materials-documents.s3.amazonaws.com/documents/standards-and-assessments/Texas-ELPS.pdf'
    }

    useEffect(() => {
        localStorage.setItem('standard', standard!=null?standard:'')
    }, [standard])

    const StandardDropdown = () => {
        return <>
            <div>
                <span className='col-form-label font-size-sm fw-bold color-grey700'>Standards:</span>
            </div>
            <div className='d-flex align-items-center'>
                <Form.Select
                    className='form-select font-size-sm w-auto bg-transparent py-0 ps-2 pe-4 fw-bold color-grey700'
                    value={standard}
                    onChange={(event) => setStandard(event.target.value)}
                    style={{backgroundPosition: 'right .4rem center'}}
                >
                    <option value=''>Select Standard</option>
                    <option value='elpa21'>ELPA21</option>
                    <option value='actfl'>ACTFL</option>
                    <option value='wida'>WIDA</option>
                    <option value='ccss'>CCSS</option>
                    <option value='ca'>CA-ELD/CA-CCSS</option>
                    <option value='ny'>NY-ELA</option>
                    <option value='tx'>TexasELPS</option>
                </Form.Select>
                <FontAwesomeIcon icon={faInfoCircle} className='mx-2 color-grey950' style={{cursor: 'pointer'}} onClick={() => setStandardModalShow(true)}/>
            </div>
            <Modal
                show={standardModalShow}
                onHide={()=>setStandardModalShow(false)}
                dialogClassName={!standard?'modal-90w modal-min-width standard-modal-small':'modal-90w modal-min-width standard-modal'}
                size="lg"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton className="bg-blue100">
                    <Modal.Title className="ps-3 fw-bold color-grey900">
                        Assessment Standards
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-color">
                    {!standard?
                        <div className='mt-5 font-size-xl' style={{height: '50vh', textAlign: 'center'}}>
                            Please select an Assessment Standard.
                        </div>
                        :
                        <iframe src={standard_dict[standard]} title="standards"
                            style={{width: '100%', height: '90vh'}}
                        ></iframe>
                    }
                </Modal.Body>
            </Modal>
        </>
    }

    const [showModel, setModel] = useState(false)
    const [student, setStudent] = useState({})
    const [book, setBook] = useState('')
    const ModalStudent = () => { return <StudentModal modal_status={showModel} onClose={closeModal} student={student} book={book} /> }
    const closeModal = () =>{
        setStudent('')
        setBook('')
        setModel(false)
    }
    const handleClick = (student, book_name = '') =>{
        setStudent(student)
        setBook(book_name)
        setModel(true)
    }

    useLayoutEffect(() => {
        if (isLoading) {
            document.body.style.overflowY = "hidden";
            document.body.style.height = "100%";
        }
        if (!isLoading) {
            document.body.style.overflowY = "auto";
            document.body.style.height = "auto";
        }
    }, [isLoading]);

    return (
      <>
        <LoadingOverlay
          active={isLoading}
          spinner
          text="Loading content..."
          className="spinner"
        >
          <Header />
          <section className="section section-sm pt-0 bg-color" id="pages">
            <Container className="py-4">
              <ModalStudent />

              <Tab.Container
                defaultActiveKey="recent_activity"
                onSelect={handleTabClick}
              >
                <Row className="align-items-center">
                  <Col md={3}>
                    <div className="mx-auto" style={{ width: "fit-content" }}>
                      <div className="col-auto px-1">
                        <label className="col-form-label font-size-sm p-0 fw-bold color-grey700">
                          Class:
                        </label>
                      </div>
                      <ClassDropdown />

                      <div className="col-auto">
                        <label className="col-form-label font-size-sm p-0 fw-bold color-grey700">
                          Language:
                        </label>
                      </div>
                      <div className="col-auto">
                        <select
                          className="form-select font-size-sm bg-transparent py-0 ps-2 pe-4 fw-bold color-grey700"
                          style={{
                            outline: "none",
                            backgroundPosition: "right .4rem center",
                          }}
                          value={lang}
                          onChange={handleLangChange}
                        >
                          {LanguageAPI.list()
                            .filter((item) => {
                              return typeof item.id === "number";
                            })
                            .map((item) => {
                              return { label: item.label, code: item.code };
                            })
                            .reduce((acc, obj) => {
                              if (!acc.some((item) => item.code === obj.code)) {
                                acc.push(obj);
                              }
                              return acc;
                            }, [])
                            .map((item, index) => {
                              return (
                                <option
                                  key={"filter-language" + index}
                                  value={item.code}
                                >
                                  {item.label}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <Nav
                      fill
                      variant="pills"
                      className="flex-column flex-sm-row dashboard p-0"
                      onClick={handleTabClick}
                    >
                      <Nav.Item className="col-4">
                        <Nav.Link
                          eventKey="recent_activity"
                          className="mb-sm-3 mb-md-0 color-grey800 border-right"
                        >
                          <img
                            src={imgRecent}
                            className="tab-icon"
                            alt="recent icon"
                          />
                          Recent Activity
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="col-4">
                        <Nav.Link
                          eventKey="assigned_books"
                          className="mb-sm-3 mb-md-0 color-grey800 border-right"
                        >
                          <img
                            src={imgAssigned}
                            className="tab-icon"
                            alt="assigned icon"
                          />
                          Books Read
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="col-4">
                        <Nav.Link
                          eventKey="student"
                          className="mb-sm-3 mb-md-0 color-grey800"
                        >
                          <img
                            src={imgStudent}
                            className="tab-icon"
                            alt="student icon"
                          />
                          All Students
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col md={3}>
                    <div className="mx-auto" style={{ width: "fit-content" }}>
                      <StandardDropdown />
                    </div>
                  </Col>
                </Row>

                {
                  <Tab.Content>
                    <Tab.Pane eventKey="recent_activity" className="py-4">
                      <RecentActivity
                        data={store.getState().recent.data.recent_activity}
                        handleClick={handleClick}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="assigned_books" className="py-4">
                      <AssignedBooks
                        ref={assignedRef}
                        lang={lang}
                        handleClick={handleClick}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="student" className="py-4">
                      <Student handleClick={handleClick} />
                    </Tab.Pane>
                  </Tab.Content>
                }
              </Tab.Container>
            </Container>
          </section>
        </LoadingOverlay>
      </>
    );
};