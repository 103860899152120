export const LanguageAPI = {
    list: function () {
        return [
            {code:'',label:'No Restriction'},
            // {
            //   id: noneBilingualId,
            //   label: 'No Translation',
            //   translation: '',
            //   code: noneBilingualCode,
            //   flag: undefined,
            //   label2: '',
            // },
            {
              id: 1,
              label: 'English',
              translation: 'English',
              code: 'EN',
              flag: '/svgs/flags/english.svg',
            },
            {
              id: 2,
              label: 'Spanish',
              translation: 'Español',
              code: 'ES',
              flag: '/svgs/flags/spanish.svg',
            },
            {
              id: 3,
              label: 'Madarin',
              translation: '中文',
              label2: '(with pinyin)',
              code: 'ZH',
              withPinyin: true,
              flag: '/svgs/flags/chinese.svg',
            },
            {
              id: 4,
              label: 'Madarin',
              translation: '中文',
              label2: '(no pinyin)',
              code: 'ZH',
              withPinyin: false,
              flag: '/svgs/flags/chinese.svg',
            },
            {
              id: 5,
              label: 'Vietnamese',
              translation: 'Tiếng Việt',
              code: 'VI',
              flag: '/svgs/flags/vietnamese.svg',
            },
            {
              id: 6,
              label: 'Arabic',
              translation: 'عربي',
              code: 'AR',
              flag: '/svgs/flags/arabic.svg',
            },
            {
              id: 7,
              label: 'Korean',
              translation: '한국어',
              code: 'KO',
              flag: '/svgs/flags/korean.svg',
            },
            {
              id: 8,
              label: 'Filipino',
              translation: 'Filipino',
              code: 'TL',
              flag: '/svgs/flags/filipino.svg',
            },
            {
              id: 9,
              label: 'Bengali',
              translation: 'বাংলা',
              code: 'BN',
              flag: '/svgs/flags/bengali.svg',
            },
            {
              id: 10,
              label: 'Portuguese',
              translation: 'Portugues',
              code: 'PT',
              flag: '/svgs/flags/portuguese.svg',
            },
            // {
            // id: 11,
            // label: 'CANTONESE',
            // translation: '',
            //   code: 'HK',
            //   flag: null,
            // },
            // {
            // id: 12,
            // label: 'TAIWANES',
            // translation: '',
            // code: 'TW',
            // flag: null,
            // },
            {
              id: 13,
              label: 'Polish',
              translation: 'Polski',
              code: 'PL',
              flag: '/svgs/flags/polish.svg',
            },
            {
              id: 14,
              label: 'Ukrainian',
              translation: 'українська',
              code: 'UK',
              flag: '/svgs/flags/ukrainian.svg',
            },
            {
              id: 15,
              label: 'Dari',
              translation: 'دری',
              code: 'DR',
              flag: '/svgs/flags/dari.svg',
            },
            {
              id: 16,
              label: 'Pashto',
              translation: 'پښتو',
              code: 'PS',
              flag: '/svgs/flags/dari.svg',
            },
          ]

    }
}